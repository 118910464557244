import React from 'react'
import Layout from '../components/layout'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import Map from '../assets/map.svg'
import LocalImageHook from '../hooks/local-images-hook'
import Img from 'gatsby-image'

export default () => {
  const title = 'About'
  const aboutCover = LocalImageHook().about_cover.childImageSharp
  console.log(aboutCover)
  return (
    <Layout>
      <Helmet title={title} />
      <div className="wrap px-6 mx-auto py-8 md:px-20 md:pt-12 md:pb-32">
        <h1 className="uppercase font-light tracking-widest text-gray-33 text-20 md:text-36">
          ABOUT US
        </h1>
        <div className="md:flex md:max-w-1450 md:mx-auto">
          <div className="mt-8 mb-6 md:mt-0 md:mb-0 md:flex-1 md:order-1 md:flex md:items-center">
            <Img className="w-full object-cover" fluid={aboutCover.fluid} />
          </div>
          <div className="px-4 md:px-0 md:w-1/2 md:order-0 md:mt-24 md:pr-10 xl:pr-24">
            <p className="text-13-23 text-gray-4d font-regular md:text-15-22 xl:text-20-40">
              從引用 Rumi
              詩句與不同領域之職人合作詮釋，透過新能量及樣貌來與世界對話，這便是兼具風格與藝術的
              RYZEN 提出 #URPROJECT
              計畫，期待藉由此計畫帶來更多的力量與溫度。繼上一回與日本人氣風格攝影師
              RK 的 “STOP ACTING SO SMALL” 企劃後，RYZEN 本回則是與 oqLiq
              合作，而 oqLiq 是首個入選至 MAN Paris 參展且於 NYFW 2020
              走上伸展台的台灣品牌，欲透過他們的獨特意念去詮釋 “YOU ARE THE
              UNIVERSE”。
            </p>
          </div>
        </div>
        <hr className="my-6 border-gray-99 md:mx-auto xl:max-w-1450 md:my-20" />
        <h1 className="uppercase font-light tracking-widest text-gray-33 text-20 md:text-36">
          CONTACT US
        </h1>
        <div className="px-4 md:px-0 md:flex md:mt-10 md:items-center md:max-w-1450 md:mx-auto">
          <div className="mt-6 md:mt-0 md:order-1 md:flex-1">
            <Map className="w-full h-auto md:max-w-540 md:mx-auto" />
          </div>
          <div className="md:order-0 md:w-1/2">
            <h3 className="text-17 text-gray-33 tracking-wide font-medium md:text-2xl">
              RYZEN URP
            </h3>
            <div className="flex flex-wrap text-13-31 text-4d mt-8 md:text-lg md:font-light md:justify-between md:max-w-540 md:pr-6 lg:text-xl">
              <div className="w-16 md:w-auto">地址</div>
              <div className="md:w-350">台中市潭子區中山路三段275巷47號旁</div>
              <div className="w-full md:h-4"></div>
              <div className="w-16 md:w-auto">營業時間</div>
              <div className="md:w-350">10:00-19:00</div>
              <div className="w-full md:h-4"></div>
              <div className="w-16 md:w-auto">電話</div>
              <div className="md:w-350">04-2255-7888</div>
              <div className="w-full md:h-4"></div>
              <div className="w-16 md:w-auto">信箱</div>
              <div className="md:w-350">
                <a href="mailto:info@ryzen.com.tw">info@ryzen.com.tw</a>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-99 md:mx-auto xl:max-w-1450 md:my-20" />
        <div className="px-4 md:px-0 md:max-w-1450 md:mx-auto">
          <h3 className="text-17 text-gray-33 tracking-wide font-medium md:text-2xl">
            藝術家招募計畫
          </h3>
          <p className="text-13-23 text-gray-4d font-regular mt-8 md:text-15-22 md:font-light xl:text-20-40">
            實習期間，將於實作中累積經驗、體驗替代空間的工作型態。不僅發揮個人專長、更能從中獲得自我成長。
            <br />
            申請日期：即日起至 2020/07/31（五）23:00止
            <br />
            報名連結:{' '}
            <a href="https://forms.gle/GGEVGx4SCDbFQRP6A">
              https://forms.gle/GGEVGx4SCDbFQRP6A
            </a>
            <br />
          </p>
          <p className="text-13-23 text-gray-4d font-regular mt-4 md:text-15-22 md:font-light md:mt-6 xl:text-20-40">
            During the internship, you will accumulate experience and experience
            the work style of alternative spaces. Not only can I use my personal
            expertise, but also gain self-growth from it.
            <br />
            Application date: from now until 2020/07/31 (Fri) 23:00
            <br />
            Registration link:{' '}
            <a href="https://forms.gle/GGEVGx4SCDbFQRP6A">
              https://forms.gle/GGEVGx4SCDbFQRP6A
            </a>
            <br />
          </p>
        </div>
      </div>
    </Layout>
  )
}
